import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Hooks
import useUtms from 'src/hooks/useUtms'

// Components
import ImageWebp from 'src/components/ImageWebp'

import ComoContratarJSON from '../../assets/data/ComoContratar.json'

import { Circle, Section } from './style'

type ComoContratarProps = {
  description: string;
}

const ComoContratar = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const arrayNumbers: number[] = [
    312, 336, 376, 456,
  ]

  const title = 'Como contratar Capital de Giro para empresas no Inter?'
  const paragraph = 'Contrate capital de giro sem burocracias no Inter Empresas!'

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 d-md-none'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-3 fw-600 text-grayscale--500'>
              {title}
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-xl-22 fw-400 text-grayscale--500'>
              {paragraph}
            </p>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 d-flex justify-content-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/mulher-empreendedora-empresas/image.webp'
              altDescription='Proprietária de uma loja de porcelanas realizando anotações em uma prancheta.'
              arrayNumbers={arrayNumbers}
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 offset-lg-1 offset-xl-2 mt-4'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-3 fw-600 text-grayscale--500 d-none d-md-block'>
              {title}
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-xl-22 fw-400 text-grayscale--500 d-none d-md-block'>
              {paragraph}
            </p>
            {
              ComoContratarJSON.map((item: ComoContratarProps, index: number) => (
                <div key={item.description} className='d-flex align-items-center mb-lg-2'>
                  <Circle className='mr-3'><p className='fs-16 mt-3 text-white'>{index + 1}</p></Circle>
                  <div><p className='fs-16 lh-20 fw-700 text-grayscale--500'>{item.description}</p></div>
                </div>
              ))
            }
            <a
              href={utmLink}
              target='_blank'
              rel='noreferrer'
              data-home-pj='hero'
              className='fs-14 btn text-white btn--lg text-none rounded-3 my-2 fw-700 abrir-conta'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  section_name: 'Como contratar Capital de Giro para empresas no Inter?',
                  element_name: 'Abrir Conta PJ gratuita',
                  redirect_url: utmLink,
                })
              }}
            >
              Abrir Conta PJ gratuita
            </a>
            <a
              href='https://contadigital.inter.co/capital-giro-fgi-peac'
              target='_blank'
              rel='noreferrer'
              data-home-pj='hero'
              className='fs-14 btn btn--lg text-none rounded-3 my-2 fw-700 disponibilidade'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  section_name: 'Como contratar Capital de Giro para empresas no Inter?',
                  element_name: 'Consultar disponibilidade',
                  redirect_url: 'https://contadigital.inter.co/capital-giro-fgi-peac',
                })
              }}
            >
              Consultar disponibilidade
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoContratar
